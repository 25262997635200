const Footer = () => {
    return (
      <footer className="footer">
        <div className="footer-content">
          {/* Redes sociales */}
          <div className="social-icons">
            <a href="https://www.facebook.com/newtrendsshopgt" target="_blank" rel="noopener noreferrer">
              <img src="/fb.svg" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/nts.guatemala/?hl=es" target="_blank" rel="noopener noreferrer">
              <img src="/insta.svg" alt="Instagram" />
            </a>
            <a href="https://www.tiktok.com/@nts.guatemala" target="_blank" rel="noopener noreferrer">
              <img src="/tiktok.svg" alt="TikTok" />
            </a>
          </div>
  
          {/* Links relevantes */}
          <div className="footer-links">
            <a href="#about">About Us</a>
            <a href="#collection">Collection</a>
            <a href="#contact">Contact</a>
          </div>
  
          {/* Información adicional */}
          <div className="footer-bottom">
            <p>© 2024 New Trends Shop. All rights reserved.</p>
            <p>Designed by Nexuco</p>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;
  