import React, {useState} from "react";
import "./App.css"; // Agregaremos los estilos en este archivo
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Estilos del carrusel
import Footer from "./Componentes/Footer";

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="landing-page">
      <header className="header">
        <img src="/logo.png" alt="Company Logo" className="logo" />
        <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
          <a href="#about">About Us</a>
          <a href="#collection">Collection</a>
          <a href="#contact">Contacto</a>
          <a href="/portal">Portal</a>
        </nav>
         {/* Botón de menú hamburguesa */}
         <button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? "✕" : "☰"} {/* Cambia el ícono según el estado */}
        </button>
      </header>
      
      <section className="hero">
        <div className="overlay"></div>
        <video autoPlay muted loop playsInline className="background-video">
          <source src="/background2.mp4" type="video/mp4" media="(min-width: 1920px)" />
          <source src="/background2.mp4" type="video/mp4" media="(max-width: 1920px)" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1>New Trends Shop</h1>
        </div>
      </section>

      <section id="about" className="about-us">
        <h2>About Us</h2>
        <p>Ropa, Calzado & Accesorios. NTS tu estilo de vida! .</p>
        <img src="/about-image1.png" alt="About us" />
        <img src="/about-image2.png" alt="About us" />
      </section>

      <section id="collection" className="collection">
        <h2>Our Collection</h2>
        <Carousel>
          <div>
            <img src="/collection1.jpg" alt="Collection Item 1" />
          </div>
          <div>
            <img src="/collection2.jpg" alt="Collection Item 2" />
          </div>
          <div>
            <img src="/collection3.jpg" alt="Collection Item 3" />
          </div>
          <div>
            <img src="/collection4.jpg" alt="Collection Item 3" />
          </div>
        </Carousel>
      </section>
      <Footer />
    </div>
  );
}; 

export default App;
